































































import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { CHANNEL_OPTIONS, DISPLAY_INTERVALS } from '@/constants/filters.constants';
import { NS_FILTERS_CHANNELS } from '@/constants/app.constants';
import { Options } from '@/models/app.model';
import { FiltersRealtimeChannels } from '@/models/states/filters-state.model';
import { Station } from '@/models/station.model';
import { QueryService } from '@/services/query.service';
import BPFilterSelect from '@/components/shared/BPFilterSelect.component.vue';
import UnitSelect from '@/components/shared/UnitSelect.component.vue';

@Component({
  name: 'OptionsRealtimeChannels',
  components: {
    UnitSelect,
    BPFilterSelect,
  },
})
export default class OptionsRealtimeChannels extends Vue {
  @Prop({ default: () => null }) public station?: Station | null;
  @Prop({ default: true }) public isOpen!: boolean;
  @Action('updateFilters', { namespace: NS_FILTERS_CHANNELS }) public updateFilters: any;
  @Getter('getFilters', { namespace: NS_FILTERS_CHANNELS }) public filters?: FiltersRealtimeChannels;

  public isOpenInner: number | undefined = 0;
  public innerFilters?: Partial<FiltersRealtimeChannels> = {};
  public nodes: Options<number>[] = [];
  public channels: Options<number[]>[] = CHANNEL_OPTIONS;
  public displayIntervals: Options<number>[] = DISPLAY_INTERVALS;

  public mounted() {
    if (this.station) {
      this.nodes = this.station.nodes.map((node) => ({
        name: `Node ${node.nodeId}`,
        value: node.nodeId,
      }));

      if (this.station.sensorProvisioning === 'a') {
        this.applyFilters(
          _.extend({}, this.filters, {
            channels: [[0, 1, 2]],
          }),
        );
      }
    }
    if (!_.isEmpty(this.$route.query)) {
      this.applyFilters({
        bpFilter: this.$route.query.bpFilter === 'true',
        displayInterval: parseInt(this.$route.query.displayInterval as string, 10),
        nodes: QueryService.getNodes(this.$route),
        channels: QueryService.getChannels(this.$route),
        unit: QueryService.getUnit(this.$route),
      });
    }

    if (!_.isEmpty(this.filters)) {
      this.onFiltersChange();
    }
  }

  @Watch('isOpen')
  public onOpenChange() {
    this.isOpenInner = this.isOpen ? 0 : undefined;
  }

  @Watch('filters')
  public onFiltersChange() {
    this.innerFilters = _.cloneDeep(this.filters);
    this.updateQueryParams();
  }

  public applyFilters(filters: Partial<FiltersRealtimeChannels> | undefined = this.innerFilters) {
    this.updateFilters(filters);
  }

  public updateQueryParams() {
    if (!this.filters) {
      return;
    }

    this.$router
      .replace(
        _.extend({}, this.$route, {
          query: QueryService.convertQueryParamsToString(this.filters),
        }),
      )
      .catch(() => {});
  }
}
