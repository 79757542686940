
























import _ from 'lodash';
import moment from 'moment-timezone';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  CLEAR_MAP_AFTER,
  NS_ALERTS,
  NS_FILTERS_REALTIME,
  NS_POI,
  NS_STATIONS,
  REAL_TIME_POOL_HEALTH_INTERVAL,
  REAL_TIME_POOL_INTERVAL,
  REAL_TIME_POOL_STATE_INTERVAL,
  TEST_STATIONS,
} from '@/constants/app.constants';
import { Message, SeismicEventInProgress } from '@/models/message.model';
import { Station } from '@/models/station.model';
import { MessagesService } from '@/services/messages.service';
import { PointOfInterest } from '@/models/position.model';
import { FiltersRealtime } from '@/models/states/filters-state.model';
import OptionsRealtime from '@/components/options-panels/OptionsRealtime.component.vue';
import RealTimeMap from '@/components/real-time/RealTimeMap.component.vue';
import RealTimeChart from '@/components/real-time/RealTimeChart.component.vue';

@Component({
  name: 'RealTimeMonitor',
  components: {
    OptionsRealtime,
    RealTimeChart,
    RealTimeMap,
  },
})
export default class RealTimeMonitor extends Vue {
  @Getter('getStationLocations', { namespace: NS_STATIONS }) stations?: Station[];
  @Getter('getFilters', { namespace: NS_FILTERS_REALTIME }) public filters?: FiltersRealtime;
  @Getter('getAllPointsOfInterest', { namespace: NS_POI }) pointsOfInterest?: PointOfInterest[];

  @Action('fetchPointsOfInterest', { namespace: NS_POI }) public fetchPointsOfInterest: any;
  @Action('fetchStationLocations', { namespace: NS_STATIONS }) public fetchStations: any;
  @Action('addAlert', { namespace: NS_ALERTS }) public addAlert: any;
  @Action('clearAlerts', { namespace: NS_ALERTS }) public clearAlerts: any;
  @Action('setSeismicEventInProgress', { namespace: NS_STATIONS }) public setSeismicEventInProgress: any;
  @Getter('getSeismicEventInProgress', { namespace: NS_STATIONS }) public isSeismicEventInProgress?: boolean;
  @Action('updateFilters', { namespace: NS_FILTERS_REALTIME }) public updateFilters: any;

  public isFiltersOpen: boolean = false;
  public messages: Message[] = [];
  public timerId: any = null;
  public timeoutId: any = null;
  public eventsTimerId: any = null;
  public messagesTimeoutId: any = null;
  public mapIncrement: number = 0;
  public messagesOffset: string | null = null;
  public displayInterval: number = 5;
  public isDestroyed: boolean = false;
  public seismicEventInProgress: SeismicEventInProgress | null = null;

  public mounted() {
    this.setSeismicEventInProgress(false);

    this.fetchPointsOfInterest();
    this.onEventInProgressChange();

    if (this.stations && this.filters) {
      this.onFiltersChange();
    }

    this.timerId = setInterval(() => {
      this.fetchStations();
    }, REAL_TIME_POOL_HEALTH_INTERVAL);

    this.eventsTimerId = setInterval(() => {
      this.checkEventState();
    }, REAL_TIME_POOL_STATE_INTERVAL);
  }

  public destroyed() {
    this.isDestroyed = true;
    clearInterval(this.timerId);
    clearInterval(this.eventsTimerId);
    clearInterval(this.timeoutId);
    clearInterval(this.messagesTimeoutId);
  }

  public onFiltersToggle(isOpen: number) {
    this.isFiltersOpen = isOpen === 0;
  }

  @Watch('isSeismicEventInProgress')
  public onEventInProgressChange() {
    if (this.isSeismicEventInProgress) {
      this.showSeismicEventAlert();
    }
  }

  public filteredStations() {
    return this.stations?.filter((station) => this.filters?.stationIds?.includes(station.stationId));
  }

  public stationsInSeismicEvent() {
    return this.seismicEventInProgress?.impactedStationIds || [];
  }

  public showSeismicEventAlert() {
    this.clearAlerts();
    this.addAlert({
      header: 'A Seismic Event has been detected',
      message: 'Please follow the map to see the changes realtime',
      type: 'warning',
      timeout: 5000,
    });
  }

  @Watch('filters')
  public onFiltersChange() {
    if (this.stations && this.filters) {
      clearInterval(this.timeoutId);
      this.loadMessages(this.stations, this.filters);
    }
  }

  private getBackIntervalMin(): number {
    return moment().subtract(this.displayInterval, 'minutes').valueOf();
  }

  private loadMessages(stations: Station[], filters: FiltersRealtime) {
    if (!stations.length || !this.stations) {
      return;
    }

    const testStationsInFilters = !!filters?.stationIds?.filter((stationId) => TEST_STATIONS.includes(stationId)).length;

    MessagesService.realtimeQuery({
      from: this.getBackIntervalMin(),
      stationIds: testStationsInFilters
        ? this.stations.map((s) => s.stationId)
        : this.stations.filter((s) => !TEST_STATIONS.includes(s.stationId)).map((s) => s.stationId),
      offset: this.messagesOffset,
    }).then(
      (response) => {
        this.messagesOffset = response.offset;
        this.appendMessages(response.elements);
        this.addLoadMessagesQue();
      },
      () => {
        this.addLoadMessagesQue();
      },
    );
  }

  private appendMessages(newMessages: Message[]) {
    this.messages = MessagesService.getAppendedMessages(this.messages, newMessages, this.getBackIntervalMin());

    this.seismicEventInProgress = MessagesService.isSeismicEventInProgress(
      this.seismicEventInProgress,
      this.messages,
      this.stations ? this.stations : [],
    );

    this.setSeismicEventInProgress(!!this.seismicEventInProgress);

    if (this.seismicEventInProgress) {
      this.displayStationsInSeismicEvent();
    }
  }

  private displayStationsInSeismicEvent() {
    if (this.filters && this.seismicEventInProgress) {
      if (!_.isEqual(this.filters.stationIds, this.seismicEventInProgress.impactedStationIds)) {
        this.updateFilters(
          _.extend({}, this.filters, {
            stationIds: _.union(this.filters.stationIds, this.seismicEventInProgress.impactedStationIds),
          }),
        );
      }
    }
  }

  private checkEventState() {
    if (this.seismicEventInProgress && this.seismicEventInProgress.endTime !== -1) {
      const time = moment.unix(this.seismicEventInProgress.endTime / 1000);
      if (Math.abs(moment().diff(time, 'seconds')) >= CLEAR_MAP_AFTER) {
        this.setSeismicEventInProgress(false);
        this.seismicEventInProgress = null;
      }
    }
  }

  private addLoadMessagesQue() {
    clearInterval(this.messagesTimeoutId);
    if (this.isDestroyed) {
      return;
    }
    this.messagesTimeoutId = setTimeout(() => {
      if (this.stations && this.filters) {
        this.loadMessages(this.stations, this.filters);
      }
    }, REAL_TIME_POOL_INTERVAL);
  }
}
