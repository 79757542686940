



































import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { DISPLAY_INTERVALS } from '@/constants/filters.constants';
import { NS_FILTERS_REALTIME, NS_STATIONS } from '@/constants/app.constants';
import { Station } from '@/models/station.model';
import { FiltersRealtime } from '@/models/states/filters-state.model';
import { Options } from '@/models/app.model';
import { QueryService } from '@/services/query.service';
import BPFilterSelect from '@/components/shared/BPFilterSelect.component.vue';
import StationsSelectTree from '@/components/shared/StationsSelectTree.component.vue';
import UnitSelect from '@/components/shared/UnitSelect.component.vue';

@Component({
  name: 'OptionsRealtime',
  components: {
    UnitSelect,
    BPFilterSelect,
    StationsSelectTree,
  },
})
export default class OptionsRealtime extends Vue {
  @Getter('getStationLocations', { namespace: NS_STATIONS }) stations?: Station[];
  // @Getter('getAllStations', { namespace: NS_STATIONS }) stations?: Station[];
  @Getter('getFilters', { namespace: NS_FILTERS_REALTIME }) public filters?: FiltersRealtime;
  @Action('updateFilters', { namespace: NS_FILTERS_REALTIME }) public updateFilters: any;

  @Prop({ default: false }) public isOpen!: number | undefined;

  public innerFilters?: Partial<FiltersRealtime> = {};
  public displayIntervals: Options<number>[] = DISPLAY_INTERVALS;
  public firstRender: boolean = false;
  public isOpenInner: number | undefined = -1;

  public mounted() {
    if (!_.isEmpty(this.$route.query)) {
      this.applyFilters(
        _.extend({}, this.$route.query, {
          bpFilter: this.$route.query.bpFilter === 'true',
          displayInterval: parseInt(this.$route.query.displayInterval as string, 10) || this.filters?.displayInterval,
          stationIds: QueryService.getStations(this.$route) || this.filters?.stationIds,
          unit: QueryService.getUnit(this.$route),
        }),
        true,
      );
    }

    if (!_.isEmpty(this.filters)) {
      this.onFiltersChange();
    }

    this.onStationsChange();
  }

  @Watch('isOpen')
  public onOpenChange() {
    this.isOpenInner = this.isOpen;
  }

  @Watch('filters')
  public onFiltersChange() {
    this.innerFilters = _.cloneDeep(this.filters);
    this.updateQueryParams();
  }

  @Watch('stations')
  public onStationsChange() {
    if (this.innerFilters && this.stations && !this.firstRender) {
      if (!this.innerFilters.stationIds?.length) {
        this.innerFilters.stationIds = this.stations.map((station) => station.stationId);
      }
      this.applyFilters();
      this.firstRender = true;
    }
  }

  public updateQueryParams() {
    if (!this.filters) {
      return;
    }

    this.$router
      .replace(
        _.extend({}, this.$route, {
          query: QueryService.convertQueryParamsToString(this.filters),
        }),
      )
      .catch(() => {});
  }

  public applyFilters(filters: any = this.innerFilters, skipEqualityCheck: boolean = false) {
    if (skipEqualityCheck || !_.isEqual(filters, this.filters)) {
      this.updateFilters(filters);
    }
  }
}
