



























import _ from 'lodash';
import moment from 'moment-timezone';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  MAX_REAL_TIME_CHARTS_TO_SHOW,
  NS_ALERTS,
  NS_FILTERS_REALTIME,
  NS_STATIONS,
  REAL_TIME_POOL_HEALTH_INTERVAL,
  REAL_TIME_POOL_INTERVAL,
} from '@/constants/app.constants';
import { MESSAGE_TYPES } from '@/constants/message-types.constant';
import { Message } from '@/models/message.model';
import { PlotLineConfiguration } from '@/models/chart.model';
import { Station, StationReading } from '@/models/station.model';
import { MessagesService } from '@/services/messages.service';
import { ReadingsRealtimeRequest } from '@/models/response.model';
import { FiltersRealtime } from '@/models/states/filters-state.model';
import { ChartUtils } from '@/utils/chart.utils';
import { ReadingService } from '@/services/reading.service';
import OptionsRealtime from '@/components/options-panels/OptionsRealtime.component.vue';
import RealTimeMap from '@/components/real-time/RealTimeMap.component.vue';
import RealTimeChart from '@/components/real-time/RealTimeChart.component.vue';

@Component({
  name: 'RealTimeMonitor',
  components: {
    OptionsRealtime,
    RealTimeChart,
    RealTimeMap,
  },
})
export default class RealTimeMonitor extends Vue {
  @Getter('getAllStations', { namespace: NS_STATIONS }) stations?: Station[];
  @Getter('getStationLocations', { namespace: NS_STATIONS }) stationLocations?: Station[];
  @Getter('getFilters', { namespace: NS_FILTERS_REALTIME }) public filters?: FiltersRealtime;

  @Action('fetchStations', { namespace: NS_STATIONS }) public fetchStations: any;
  @Action('addAlert', { namespace: NS_ALERTS }) public addAlert: any;
  @Action('clearAlerts', { namespace: NS_ALERTS }) public clearAlerts: any;
  @Action('updateFilters', { namespace: NS_FILTERS_REALTIME }) public updateFilters: any;

  public plotLines: any[] = [];
  public isFiltersOpen: boolean = false;
  public messages: Message[] = [];
  public stationReadings: StationReading[] = [];
  public timerId: any = null;
  public timeoutId: any = null;
  public eventsTimerId: any = null;
  public messagesTimeoutId: any = null;
  public mapIncrement: number = 0;
  public readingsOffset: string | null = null;
  public messagesOffset: string | null = null;
  public displayInterval: number = 5;
  public isDestroyed: boolean = false;

  public mounted() {
    this.timerId = setInterval(() => {
      this.fetchStations();
    }, REAL_TIME_POOL_HEALTH_INTERVAL);
  }

  public destroyed() {
    this.isDestroyed = true;
    clearInterval(this.timerId);
    clearInterval(this.eventsTimerId);
    clearInterval(this.timeoutId);
    clearInterval(this.messagesTimeoutId);
  }

  public onFiltersToggle(isOpen: number) {
    this.isFiltersOpen = isOpen === 0;
  }

  public get filteredStationReadings(): StationReading[] {
    return _(this.stationReadings).filter({ hidden: false }).take(MAX_REAL_TIME_CHARTS_TO_SHOW).value();
  }

  @Watch('filters')
  public onFiltersChange() {
    this.displayInterval = _.get(this.filters, 'displayInterval', 5);

    if (this.stations && this.filters) {
      const stationFilters: string[] = _.get(this.filters, 'stationIds', []) || [];
      if (stationFilters.length > 3) {
        this.addAlert({
          type: 'warning',
          message: `You can see real time readings for max ${MAX_REAL_TIME_CHARTS_TO_SHOW} stations`,
          timeout: 5000,
        });
      }
      const filteredStations = _.take(stationFilters, 3);
      this.stationReadings = this.stationReadings.map((station) =>
        _.extend({}, station, {
          data: [],
          hidden: !_.includes(filteredStations, station.stationId),
        }),
      );
      this.readingsOffset = null;
      clearInterval(this.timeoutId);
      this.loadRealtimeData(this.stations, this.filters);
      this.loadMessages(this.stations);
    }
  }

  @Watch('stations')
  public onStationsChanged(stations: Station[] = []) {
    if (!this.stationReadings.length) {
      this.stationReadings = ReadingService.generateStationReadings(this.stationLocations!, this.filters);

      if (this.filters) {
        this.loadRealtimeData(stations, this.filters);
        this.loadMessages(stations);
      }
    } else {
      stations.forEach((station) => {
        const foundStation = _(this.stationReadings).find({ stationId: station.stationId }) || {};
        _.set(foundStation, 'stationStatus', station.healthStatus);
      });
    }
  }

  public getPlotLines(station: StationReading) {
    return this.plotLines.filter((plotLine: PlotLineConfiguration) => plotLine.meta.stationId === station.stationId);
  }

  private getReadingsRealtimeRequestParams(stations: Station[], filters: FiltersRealtime, displayInterval: number): ReadingsRealtimeRequest {
    return {
      applyFilters: filters.bpFilter ? [{ type: 'BUTTERWORTH_BANDPASS' }] : [],
      from: this.getBackIntervalMin(),
      stationIds: filters.stationIds && filters.stationIds.length ? _.take(filters.stationIds, MAX_REAL_TIME_CHARTS_TO_SHOW) : null,
      timeGrouping: {
        unit: 'MILLIS',
        value: 25 * displayInterval,
      },
      preferredUnits: filters.unit,
      channels: _(stations)
        .map((station) => station.meta.mainChannelId)
        .uniq()
        .value(),
      nodes: _(stations)
        .map((station) => station.meta.mainNodeId)
        .uniq()
        .value(),
      offset: this.readingsOffset,
    };
  }

  private loadRealtimeData(stations: Station[], filters: FiltersRealtime) {
    if (!stations.length || !filters.stationIds) {
      return;
    }
    ReadingService.realtimeQuery(this.getReadingsRealtimeRequestParams(stations, filters, this.displayInterval)).then(
      (response) => {
        this.readingsOffset = response.offset;
        this.stationReadings = ReadingService.getAppendedReadings(this.stationReadings, stations, response.stations, this.getBackIntervalMin());
        this.addLoadDataQue();
      },
      () => {
        this.addLoadDataQue();
      },
    );
  }

  private getBackIntervalMin(): number {
    return moment().subtract(this.displayInterval, 'minutes').valueOf();
  }

  private addLoadDataQue() {
    clearInterval(this.timeoutId);
    if (this.isDestroyed) {
      return;
    }
    this.timeoutId = setTimeout(() => {
      if (this.stations && this.filters) {
        this.loadRealtimeData(this.stations, this.filters);
      }
    }, REAL_TIME_POOL_INTERVAL);
  }

  private loadMessages(stations: Station[]) {
    if (!stations.length || !this.stations) {
      return;
    }
    MessagesService.realtimeQuery({
      from: this.getBackIntervalMin(),
      stationIds: this.stationLocations?.map((s) => s.stationId) ?? [],
      offset: this.messagesOffset,
    }).then(
      (response) => {
        this.messagesOffset = response.offset;
        this.appendMessages(response.elements);
        const plotLineMessages = this.messages.filter((message) =>
          _.includes([MESSAGE_TYPES.P_DETECTED, MESSAGE_TYPES.S_DETECTED, MESSAGE_TYPES.EVENT_ENDED], message.type),
        );
        this.plotLines = ChartUtils.convertMessagesToPlotLines(plotLineMessages, true);
        this.addLoadMessagesQue();
      },
      () => {
        this.addLoadMessagesQue();
      },
    );
  }

  private appendMessages(newMessages: Message[]) {
    this.messages = MessagesService.getAppendedMessages(this.messages, newMessages, this.getBackIntervalMin());
  }

  private addLoadMessagesQue() {
    clearInterval(this.messagesTimeoutId);
    if (this.isDestroyed) {
      return;
    }
    this.messagesTimeoutId = setTimeout(() => {
      if (this.stations) {
        this.loadMessages(this.stations);
      }
    }, REAL_TIME_POOL_INTERVAL);
  }
}
