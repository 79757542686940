

























import _ from 'lodash';
import * as Highcharts from 'highcharts';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import HighChartsOptions from '@/constants/highcharts-options.constant';
import { MessageWaveDetected } from '@/models/message.model';
import StatusIcon from '@/components/shared/StatusIcon.component.vue';
import { getTime } from '@/filters/time.filter';
import { YAxisPlotLine } from '@/models/chart.model';

@Component({
  name: 'RealTimeChart',
  components: { StatusIcon },
})
export default class RealTimeChart extends Vue {
  @Prop({ default: () => [[0, 0]] }) public data?: number[][];
  @Prop({ default: () => null }) public wavePMessage?: MessageWaveDetected;
  @Prop({ default: () => null }) public waveSMessage?: MessageWaveDetected;
  @Prop({ default: () => [] }) public plotLines?: any[];
  @Prop({ default: '' }) public stationId?: string;
  @Prop({ default: '' }) public stationName?: string;
  @Prop({ default: '' }) public stationStatus?: string;
  @Prop({ default: false }) public hasDisabledData?: boolean;
  @Prop({ default: () => 0 }) public sizeIncrement?: number;
  @Prop({ default: () => [] }) public yAxisPlotLines?: YAxisPlotLine[];
  public chartOptions: Highcharts.Options = {};

  public mounted() {
    this.chartOptions = _.cloneDeep(HighChartsOptions);
  }

  @Watch('data')
  public onDataChange(readings: number[][]) {
    _.set(this.chartOptions, 'series[0].data', readings);
  }

  @Watch('sizeIncrement')
  public onSizeChanged() {
    window.dispatchEvent(new Event('resize'));
  }

  @Watch('plotLines', { deep: true })
  public onPlotLinesChange() {
    _.set(this.chartOptions, `xAxis.plotLines`, this.plotLines);
  }

  @Watch('waveSMessage')
  public onSWaveMessageChange(waveMessage: MessageWaveDetected) {
    if (!waveMessage) {
      return;
    }
    const index = 1;
    _.set(this.chartOptions, `xAxis.plotLines[${index}].value`, waveMessage.time);
    _.set(this.chartOptions, `xAxis.plotLines[${index}].label.text`, `${getTime(waveMessage.time)}`);
  }

  @Watch('wavePMessage')
  public onPWaveMessageChange(waveMessage: MessageWaveDetected) {
    if (!waveMessage) {
      return;
    }
    const index = 0;
    _.set(this.chartOptions, `xAxis.plotLines[${index}].value`, waveMessage.time);
    _.set(this.chartOptions, `xAxis.plotLines[${index}].label.text`, `${getTime(waveMessage.time)}`);
  }
}
