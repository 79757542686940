






















import _ from 'lodash';
import moment from 'moment-timezone';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { REAL_TIME_POOL_INTERVAL } from '@/constants/app.constants';
import { getNameByChannelId } from '@/filters/channel.filter';
import { roundToDecimals } from '@/utils/math.utils';
import { Station, StationHealth } from '@/models/station.model';
import { FiltersRealtimeChannels } from '@/models/states/filters-state.model';
import { ChartConfiguration } from '@/models/chart.model';
import { ReadingService } from '@/services/reading.service';
import OptionsRealtimeChannels from '@/components/options-panels/OptionsRealtimeChannels.component.vue';
import SeismicChart from '@/components/charts/SeismicChart.component.vue';
import StatusIcon from '@/components/shared/StatusIcon.component.vue';

const stationsNS: string = 'stations';
const filtersNS: string = 'filtersRealtimeChannels';

@Component({
  name: 'RealTimeChannelMonitor',
  components: {
    OptionsRealtimeChannels,
    SeismicChart,
    StatusIcon,
  },
})
export default class RealTimeChannelMonitor extends Vue {
  @Prop() public stationId?: string;
  @Getter('getFilters', { namespace: filtersNS }) public filters?: FiltersRealtimeChannels;
  @Getter('getStationHealth', { namespace: stationsNS }) getStationHealth?: StationHealth;
  @Getter('getStation', { namespace: stationsNS }) station?: Station;
  @Getter('getStationHealthError', { namespace: stationsNS }) stationHasError?: boolean;
  @Action('fetchStationHealth', { namespace: stationsNS }) public fetchStationHealth: any;
  @Action('fetchStation', { namespace: stationsNS }) public fetchStation: any;

  public channelReadings: ChartConfiguration[] = [];
  public snackbar: boolean = false;
  public readingsOffset: string | null = null;
  public displayInterval: number = 15;
  public timeoutId: any = null;
  public onChange: any;
  public isFiltersOpen: boolean = true;

  public mounted() {
    this.fetchStationHealth({ stationId: this.stationId });
    this.fetchStation({ stationId: this.stationId });
    this.onChange = _.debounce(this.onChangeAction, 1000);
    this.onChange();
  }

  public destroyed() {
    clearInterval(this.timeoutId);
  }

  @Watch('filters')
  public onFiltersChange() {
    this.onChange();
  }

  @Watch('station')
  public onStationLoad() {
    this.onChange();
  }

  @Watch('stationHasError')
  public onStationError() {
    this.snackbar = true;
    setTimeout(() => {
      this.$router.replace('/real-time-monitor');
    }, 3000);
  }

  public onFiltersToggle(isOpen: number) {
    this.isFiltersOpen = isOpen === 0;
  }

  public onChangeAction() {
    this.channelReadings = [];
    setTimeout(() => {
      if (this.filters && this.station) {
        this.displayInterval = _.get(this.filters, 'displayInterval', 15);
        this.channelReadings = this.generateChannels(this.station, this.filters);
        this.readingsOffset = null;
        clearInterval(this.timeoutId);
        this.loadRealtimeData(this.station, this.filters);
      }
    }, 100);
  }

  private loadRealtimeData(station: Station, filters: FiltersRealtimeChannels) {
    const nodes = _.isArray(filters.nodes) && filters.nodes.length ? filters.nodes : null;
    ReadingService.realtimeQuery({
      applyFilters: filters.bpFilter ? [{ type: 'BUTTERWORTH_BANDPASS' }] : [],
      from: this.getBackIntervalMin(),
      stationIds: [station.stationId],
      timeGrouping: {
        unit: 'MILLIS',
        value: 25 * this.displayInterval,
      },
      channels: _(filters.channels).flatten().value(),
      nodes,
      offset: this.readingsOffset,
      preferredUnits: filters.unit,
    }).then(
      (response) => {
        this.readingsOffset = response.offset;
        this.appendData(response.stations[station.stationId]);
        this.addLoadDataQue();
      },
      () => {
        this.addLoadDataQue();
      },
    );
  }

  private appendData(newStationData: any) {
    const channelReadings = _.cloneDeep(this.channelReadings);
    channelReadings.forEach((channel) => {
      channel.series.forEach((node) => {
        const path = `nodes.${node.key}.channels.${channel.key}`;
        const dataPath = `${path}.sensorReadings`;
        const statusPath = `${path}.channelStatuses`;
        const newData = _.get(newStationData, dataPath, []).map((data: any) => [data.time, data.value && roundToDecimals(data.value, 6)]);
        const newStatusData = _.get(newStationData, statusPath, []);
        const minTime = this.getBackIntervalMin();
        if (newData.length > 0) {
          const combinedData = _(node.data)
            // exclude data points not in the desired interval or that appear in the
            // newly fetched data again due to padding reasons
            .reject((item) => item[0] <= minTime || item[0] >= newData[0][0])
            .union(newData)
            .value();
          const combinedStatuses = _(node.statuses)
            .reject((item) => item.from <= minTime)
            .union(newStatusData)
            .value();

          const hasDisabledData = combinedStatuses.filter((s: any) => s.channelStatus !== 'ENABLED').length > 0;
          _.set(node, 'data', combinedData);
          _.set(node, 'statuses', combinedStatuses);
          _.set(node, 'hasDisabledData', hasDisabledData);
        }
      });
    });

    this.channelReadings = channelReadings;
  }

  private addLoadDataQue() {
    clearInterval(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      if (this.station && this.filters) {
        this.loadRealtimeData(this.station, this.filters);
      }
    }, REAL_TIME_POOL_INTERVAL);
  }

  private getBackIntervalMin(): number {
    return moment().subtract(this.displayInterval, 'minutes').valueOf();
  }

  private getChannelNumbers(sensorProvisioning: string, stationChannelCount: number): number[] {
    switch (sensorProvisioning) {
      case 'a':
        return [0, 1, 2];
      case 'v':
        return [3, 4, 5];
      case 'av':
      case 'va':
        return [0, 1, 2, 3, 4, 5];
      default:
        return [...Array(stationChannelCount).keys()];
    }
  }

  private generateChannels(station: Station, filters: FiltersRealtimeChannels): ChartConfiguration[] {
    const channelReadings: ChartConfiguration[] = [];
    const filteredChannels = _(_.get(filters, 'channels', [])).flatten().value();
    const filteredNodes = _.get(
      filters,
      'nodes',
      station.nodes.map((node) => node.nodeId),
    );
    const availableChannels = this.getChannelNumbers(station.sensorProvisioning, station.channelCount);

    availableChannels.forEach((channelId) => {
      if (!_.includes(filteredChannels, channelId)) {
        return;
      }
      channelReadings.push({
        name: getNameByChannelId(channelId),
        key: channelId,
        series: _(station.nodes)
          .map((node) => {
            if (!_.isEmpty(filteredNodes) && filteredNodes !== null && !_.includes(filteredNodes, node.nodeId)) {
              return null;
            }
            return {
              key: `${node.nodeId}`,
              name: `Node ${node.nodeId}`,
              nodeStatus: node.healthStatus,
              data: [],
            };
          })
          .compact()
          .value(),
      });
    });

    return channelReadings;
  }
}
